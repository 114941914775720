import { Link } from "@remix-run/react";
import { styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../play-core/styles/Viewports';
import { TransparentButton } from '../ClickableComponents/Button';
export const TitleSection = styled.h2.withConfig({
  displayName: "MediaListTitlestyles__TitleSection",
  componentId: "sc-1chjcg1-0"
})(["display:flex;margin:0;", ";", "{", ";}", "{", ";}", "{", ";}"], Fonts.bold(18), Viewports.for('medium'), Fonts.bold(24), Viewports.for('large'), Fonts.bold(28), Viewports.for('xLargeUp'), Fonts.bold(34));
export const TitleLink = styled(Link).withConfig({
  displayName: "MediaListTitlestyles__TitleLink",
  componentId: "sc-1chjcg1-1"
})(["color:unset;&:hover{color:", ";}"], Colors.GREY_d2d2d2);
export const StyledTitle = styled.div.withConfig({
  displayName: "MediaListTitlestyles__StyledTitle",
  componentId: "sc-1chjcg1-2"
})(["display:flex;justify-content:space-between;align-items:baseline;flex-wrap:wrap;color:", ";padding-bottom:16px;", "{padding-bottom:24px;}"], Colors.WHITE, Viewports.for('xLargeUp'));
export const LinkArrow = styled.span.withConfig({
  displayName: "MediaListTitlestyles__LinkArrow",
  componentId: "sc-1chjcg1-3"
})(["display:inline-flex;align-self:center;margin-left:8px;font-size:1em;", "{font-size:0.8em;}", "{margin-left:16px;font-size:0.8em;}"], Viewports.for('large'), Viewports.for('xLargeUp'));
export const ButtonAction = styled(TransparentButton).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "MediaListTitlestyles__ButtonAction",
  componentId: "sc-1chjcg1-4"
})(["margin-left:32px;flex-shrink:0;"]);
export const DescriptionText = styled.div.withConfig({
  displayName: "MediaListTitlestyles__DescriptionText",
  componentId: "sc-1chjcg1-5"
})(["max-width:1024px;"]);
export const Description = styled.div.withConfig({
  displayName: "MediaListTitlestyles__Description",
  componentId: "sc-1chjcg1-6"
})(["padding-top:16px;flex-basis:100%;", "{display:none;}", ";line-height:24px;", "{padding-top:16px;", ";line-height:28px;}"], Viewports.for('small'), Fonts.light(16), Viewports.for('largeUp'), Fonts.light(18));